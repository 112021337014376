:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 80, 80, 80;
    --background-end-rgb: 80, 80, 80;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: black;
}


label, h1, h2, .ant-form-text, .ant-divider-inner-text, a {
  color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: light;
  }
}
.header {
  height: 60px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  transition:2s;
  z-index: 1000;
 width: 100%;
}
.centered {
background:rgba(255, 255, 255, 0.424);
backdrop-filter: blur(5px);
padding: 20px;
border-radius: 10px;
display: flex;
align-items: center;
flex-direction: column;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
.container- {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}
.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}
@media only screen and (max-width: 600px) {
  .container .container- {
    max-width: 100%;
    padding: 20px;

  }
}
@media only screen and (max-width: 600px) {
  .disapear {
   display: none;
  }
}
@media only screen and (min-width: 1000px) {
  .show {
   display: none;
  }
}
@media only screen and (min-width: 1000px) {
  .menu {
   display: none;
  }
}
.child{
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}
.zoom:hover .child,
.zoom:focus .child {
  -ms-transform: scale(1.06);
  -moz-transform: scale(1.06);
  -webkit-transform: scale(1.06);
  -o-transform: scale(1.06);
  transform: scale(1.06);
}
.zoom:hover .child:before, .zoom:focus .child:before {
  display: block;
}
.animate{ animation: fadeIn 5s; }
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.big{
  font-size: 60px;
  font-weight: 900;
}
@media only screen and (max-width: 600px) {
  .big {
  font-size: 40px;
  }
}
.big-1{
  font-size: 40px;
  font-weight: 900;
}
@media only screen and (max-width: 600px) {
  .big-1 {
  font-size: 20px;
  }
}
.space{
 padding:40px
}
@media only screen and (max-width: 600px) {
  .space {
  padding: 20px;
  }
}
.ant-input {
  background-color: #ffffff40;
  border-width: none;
}
.childx:hover{
  opacity: 0.9;
}
.link {
  color: black;
}
.blog  img {
  width: 100%;
  object-fit: contain;
}